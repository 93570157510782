var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "d-flex justify-content-between mt-5" }, [
        _c("div", {}, [
          _c("span", { staticClass: "f16 fw-bold" }, [
            _vm._v(_vm._s(_vm.$t("Activity status"))),
          ]),
        ]),
        _c(
          "div",
          [
            _c("v-select", {
              staticClass: "rounded-pill fit f12",
              attrs: {
                items: _vm.option,
                mandatory: "",
                solo: "",
                dense: "",
                "hide-details": "",
              },
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c("Bar", {
              attrs: {
                "chart-options": _vm.chartOptions,
                "chart-data": _vm.chartData,
                "chart-id": _vm.chartId,
                "dataset-id-key": _vm.datasetIdKey,
                plugins: _vm.plugins,
                "css-classes": _vm.cssClasses,
                styles: _vm.styles,
                width: _vm.width,
                height: _vm.height,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._l(_vm.members, function (member, index) {
        return _c(
          "div",
          { key: index, staticClass: "d-flex align-items-center my-8" },
          [
            _c(
              "v-avatar",
              { attrs: { size: "64", rounded: "circle" } },
              [
                member.pic
                  ? _c("v-img", {
                      attrs: {
                        "lazy-src": require("@/assets/images/profile-circle.png"),
                        src: member.pic,
                      },
                    })
                  : _c("v-img", {
                      attrs: { src: require("@/assets/images/profile.jpg") },
                    }),
              ],
              1
            ),
            _c("div", { staticClass: "ms-4 me-0" }, [
              _c("span", { staticClass: "d-block f14 font-weight-bold" }, [
                _vm._v(" " + _vm._s(member.doctorFullName) + " "),
              ]),
              _c("span", { staticClass: "f13 grey--text text--darken-1" }, [
                _vm._v(_vm._s(member.roleName)),
              ]),
              _vm._v(" | "),
              _c("span", { staticClass: "f13 green--text text--darken-1" }, [
                _vm._v(_vm._s(member.questionCount)),
              ]),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }